import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de'; registerLocaleData(localeDe);
import localeEn from '@angular/common/locales/en'; registerLocaleData(localeEn);
import localeEs from '@angular/common/locales/es'; registerLocaleData(localeEs);
import localeFr from '@angular/common/locales/fr'; registerLocaleData(localeFr);
import localeIt from '@angular/common/locales/it'; registerLocaleData(localeIt);
import localePt from '@angular/common/locales/pt';import { AboutComponent } from './about/about.component';
import { SwiperDirective } from './common/directives/swiper.directive';
 registerLocaleData(localePt);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TermsComponent,
    PrivacyComponent,
    AboutComponent,
    SwiperDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideHttpClient(withInterceptorsFromDi())],
  bootstrap: [AppComponent]
})
export class AppModule { }
