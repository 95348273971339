<div class="hero-container hero-container-page">
  <h1>{{ 'PRIVACY.hero_title' | translate }}</h1>
  <p class="subtitle margin-top">{{ 'last_updated' | translate }}: {{ lastEditedDate | date }}</p>
</div>

<div class="background-container-white">
  <div class="content-container content-container-white content-container-page" [class.content-hidden]="!displayContent">
    <markdown class="text-markdown" [src]="'assets/texts/privacy/privacy-' + lang + '.md'" [line] (load)="displayContent = true"></markdown>
  </div>
</div>
