<div class="global-container">
  <!-- NAVBAR -->

  <div class="navbar-container">
    <img class="big-logo" src="../assets/svgs/logo-big.svg" alt="logo-seniorcontact" draggable="false" routerLink="/home">
    <img class="small-logo" src="../assets/svgs/logo-small.svg" alt="logo-seniorcontact" draggable="false" routerLink="/home">

    <div class="navbar-links">
      <div routerLink="/home" class="navbar-link" [class.navbar-link-selected]="currentRoute.includes('home')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>
        <p class="paragraph">{{ 'navbar_home' | translate }}</p>
      </div>

      <!-- <div routerLink="/features" class="navbar-link" [class.navbar-link-selected]="currentRoute.includes('features')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99" />
        </svg>
        <p class="paragraph">{{ 'navbar_features' | translate }}</p>
      </div> -->

      <div routerLink="/about-us" class="navbar-link" [class.navbar-link-selected]="currentRoute.includes('about-us')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
        </svg>
        <p class="paragraph">{{ 'navbar_about' | translate }}</p>
      </div>

      <a class="navbar-link" href="mailto:support@seniorcontact.app" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>
        <p class="paragraph">{{ 'navbar_contact' | translate }}</p>
      </a>
    </div>

    <div (click)="toogleMobileNavbar()" class="navbar-links-mobile">
      <div class="navbar-link navbar-link-big">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
        </svg>
        <p class="paragraph">{{ 'navbar_menu' | translate }}</p>
      </div>
    </div>
  </div>

  <!-- MAIN CONTENT -->

  <router-outlet></router-outlet>

  <!-- FOOTER -->

  <div *ngIf="currentRoute.includes('home')" class="separator-container">
    <div class="separator"></div>
  </div>

  <div class="footer-container">
    <div *ngIf="displayLangPopup" @fadeAnimation class="select-container select-container-footer">
      <div *ngFor="let langItem of allLangs; let first=first; let last=last;" class="select-box" [class.select-start]="first" [class.select-end]="last" (click)="changeLang(langItem)" [class.select-box-selected]="lang == langItem" ontouchstart>
        {{ 'lang_' + langItem | translate }}
        <p *ngIf="langItem != 'en' && langItem != 'fr'" class="subtitle language-beta-tag">BETA</p>
      </div>
    </div>

    <div class="footer-actions">
      <div (click)="displayLangPopup = !displayLangPopup" class="footer-action">
        <img src="../assets/icons/lang.svg" alt="icon-lang">
        <p class="paragraph">{{ 'lang_' + lang | translate }}</p>
      </div>
      <div (click)="toogleChangeTheme()" class="footer-action">
        <img *ngIf="theme == 'light'" src="../assets/icons/sun.svg" alt="icon-theme-light">
        <img *ngIf="theme == 'dark'" src="../assets/icons/moon.svg" alt="icon-theme-dark">
        <p class="paragraph">{{ 'theme_' + theme | translate }}</p>
      </div>
    </div>
    <div class="footer-links">
      <a class="paragraph" routerLink="/terms">{{ 'footer_terms' | translate }}</a>
      <a class="paragraph" routerLink="/privacy">{{ 'footer_privacy' | translate }}</a>
    </div>
  </div>

  <!-- OVERLAYS -->

  <div *ngIf="displayMainOverlay" (click)="displayLangPopup = false" [@.disabled]="!willDismissMainOverlay" @fadeAnimation class="absolute-overlay-white"></div>
  <div *ngIf="displayLangPopup" (click)="displayLangPopup = false" @fadeAnimation class="absolute-overlay-gray"></div>

  <!-- MOBILE NAVBAR -->

  <div *ngIf="displayMobileNavbar" class="mobile-navbar-container" @slideMenuAnimation>
    <div class="mobile-navbar-header">
      <div></div>
      <div (click)="toogleMobileNavbar()" class="navbar-link navbar-link-big">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
        <p class="paragraph">{{ 'navbar_close' | translate }}</p>
      </div>
    </div>

    <div class="mobile-navbar-content">
      <div routerLink="/home" (click)="toogleMobileNavbar()" class="navbar-link navbar-link-mobile" [class.navbar-link-selected]="currentRoute.includes('home')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>
        <p class="paragraph">{{ 'navbar_home' | translate }}</p>
      </div>

      <!-- <div class="separator"></div>

      <div routerLink="/features" (click)="toogleMobileNavbar()" class="navbar-link navbar-link-mobile" [class.navbar-link-selected]="currentRoute.includes('features')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99" />
        </svg>
        <p class="paragraph">{{ 'navbar_features' | translate }}</p>
      </div> -->

      <div class="separator"></div>

      <div routerLink="/about-us" (click)="toogleMobileNavbar()" class="navbar-link navbar-link-mobile" [class.navbar-link-selected]="currentRoute.includes('about-us')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
        </svg>
        <p class="paragraph">{{ 'navbar_about' | translate }}</p>
      </div>

      <div class="separator"></div>

      <a class="navbar-link navbar-link-mobile" href="mailto:support@seniorcontact.app" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>
        <p class="paragraph">{{ 'navbar_contact' | translate }}</p>
      </a>
    </div>
  </div>
</div>
