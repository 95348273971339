<div class="hero-container hero-container-page">
  <h1>{{ 'ABOUT.hero_title' | translate }}</h1>
  <p class="paragraph">{{ 'ABOUT.hero_paragraph' | translate }}</p>
</div>

<div class="background-container-white">
  <div class="content-container content-container-white content-container-page" [class.content-hidden]="!displayContent">
    <div class="about-container">
      <markdown class="text-markdown" [src]="'assets/texts/about/about-' + lang + '.md'" (load)="displayContent = true"></markdown>
      <!-- <div class="about-imgs-container">
        <img src="../../assets/images/message-image.jpg" alt="">
        <img src="../../assets/images/message-image.jpg" alt="">
      </div> -->
    </div>
  </div>
</div>
