<!-- Header -->

<div class="hero-container hero-container-padding hero-container-home">
  <h1>{{ 'HOME.hero_title' | translate }}</h1>
  <p class="paragraph">{{ 'HOME.hero_paragraph' | translate }}</p>
  <img (click)="redirectToStore('apple')" [src]="'../../assets/svgs/download-apple-' + theme + '.svg'" alt="download-apple" class="img-download" draggable="false">
</div>

<!-- Tablet step by step -->

<div class="background-container-white">
  <div class="content-container content-container-white content-container-spaced">
    <h2>{{ 'HOME.how_title' | translate }}</h2>

    <div class="ipad-preview-container">
      <div class="ipad-preview-left box box-flex" #ipadPreviewContainer>
        <div class="ipad-preview-text">
          <p class="small-title">{{ currentStep }} / 4</p>
          <div class="small-separator"></div>
          <swiper-container appSwiper #swiper [config]="swiperConfig">
            <swiper-slide *ngFor="let step of [1, 2, 3, 4]">
              <p class="paragraph"><markdown [data]="'HOME.how_step_' + step | translate"></markdown></p>
            </swiper-slide>
          </swiper-container>
        </div>
        <div class="buttons-flex">
          <button *ngIf="currentStep > 1" @bigSlideLeftAnimation (click)="goPreviousStep()" class="btn btn-white btn-small" type="button" ontouchstart>
            <img src="../../assets/icons/arrow-left.svg" alt="arrow-left">
          </button>
          <button *ngIf="currentStep < 4" @fadeAnimation (click)="goNextStep()" class="btn btn-orange btn-icon" type="button" ontouchstart>
            {{ 'HOME.how_btn_continue' | translate }}
            <img src="../../assets/icons/arrow-right.svg" alt="arrow-right">
          </button>
        </div>
      </div>

      <div class="ipad-preview-right">
        <img src="../../assets/svgs/tablet.svg" alt="ipad" class="ipad-preview" draggable="false">
        <div class="ipad-preview-content">
          <video *ngIf="lang == 'fr'" #videoIpad [src]="'../../assets/videos/ipad-video-' + currentStep + '-fr.mp4'" type="video/mp4" playsinline webkit-playsinline muted autoplay></video>
          <video *ngIf="lang != 'fr'" #videoIpad [src]="'../../assets/videos/ipad-video-' + currentStep + '-en.mp4'" type="video/mp4" playsinline webkit-playsinline muted autoplay></video>
          <div *ngIf="displayOverlayIpad" class="overlay-ipad-black" @fadeAnimation>
            <div class="spinner-round"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Senior's features -->

<div class="background-container-gray">
  <div class="content-container content-container-gray content-container-spaced">
    <div class="hero-container hero-container-padding hero-container-big-width">
      <h2>{{ 'HOME.features_title' | translate }}</h2>
      <p class="paragraph">{{ 'HOME.features_subtitle' | translate }}</p>
    </div>

    <div class="device-boxs">
      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/image.svg" alt="icon-image">
        <p class="paragraph">{{ 'HOME.feature_mode' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_mode_subtitle' | translate }}</p>
      </div>

      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/wrench.svg" alt="icon-wrench">
        <p class="paragraph">{{ 'HOME.feature_permissions' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_permissions_subtitle' | translate }}</p>
      </div>

      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/moon.svg" alt="icon-moon">
        <p class="paragraph">{{ 'HOME.feature_night_mode' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_night_mode_subtitle' | translate }}</p>
      </div>

      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/sound.svg" alt="icon-sound">
        <p class="paragraph">{{ 'HOME.feature_sounds' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_sounds_subtitle' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<!-- Advantages -->

<div class="background-container-white">
  <div class="content-container content-container-white content-container-spaced content-container-phone">
    <div class="all-flex-content">
      <div class="all-flex-content-left">
        <div class="flex-content" #flexContentConversation>
          <div class="flex-content-text">
            <div class="icon-header margin-bottom">
              <img src="../../assets/icons/message.svg" alt="icon-message" draggable="false">
            </div>
            <h2>{{ 'HOME.advantages_conversation_title' | translate }}</h2>
            <p class="paragraph">{{ 'HOME.advantages_conversation_subtitle' | translate }}</p>
          </div>

          <!-- Mobile image -->
          <div class="flex-content-image">
            <div class="flex-iphone-wrapper">
              <img class="flex-iphone" src="../../assets/svgs/phone.svg" alt="iphone">
              <div class="flex-iphone-content">
                <img *ngIf="lang == 'fr'" [src]="'../../assets/images/conversation-fr.jpeg'">
                <img *ngIf="lang != 'fr'" [src]="'../../assets/images/conversation-en.jpeg'">
              </div>
            </div>
          </div>
        </div>

        <div class="flex-content flex-content-margin-top" #flexContentReminders>
          <div class="flex-content-text">
            <div class="icon-header margin-bottom">
              <img src="../../assets/icons/clock.svg" alt="icon-clock">
            </div>
            <h2>{{ 'HOME.advantages_reminders_title' | translate }}</h2>
            <p class="paragraph">{{ 'HOME.advantages_reminders_subtitle' | translate }}</p>
          </div>

          <!-- Mobile image -->
          <div class="flex-content-image">
            <div class="flex-iphone-wrapper">
              <img class="flex-iphone" src="../../assets/svgs/phone.svg" alt="iphone">
              <div class="flex-iphone-content">
                <img *ngIf="lang == 'fr'" [src]="'../../assets/images/reminders-fr.jpeg'">
                <img *ngIf="lang != 'fr'" [src]="'../../assets/images/reminders-en.jpeg'">
              </div>
            </div>
          </div>
        </div>

        <div class="flex-content flex-content-margin-top flex-content-margin-bottom" #flexContentMembers>
          <div class="flex-content-text">
            <div class="icon-header margin-bottom">
              <img src="../../assets/icons/user-plus.svg" alt="icon-user-plus">
            </div>
            <h2>{{ 'HOME.advantages_members_title' | translate }}</h2>
            <p class="paragraph">{{ 'HOME.advantages_members_subtitle' | translate }}</p>
          </div>

          <!-- Mobile image -->
          <div class="flex-content-image">
            <div class="flex-iphone-wrapper">
              <img class="flex-iphone" src="../../assets/svgs/phone.svg" alt="iphone">
              <div class="flex-iphone-content">
                <img *ngIf="lang == 'fr'" [src]="'../../assets/images/members-fr.jpeg'">
                <img *ngIf="lang != 'fr'" [src]="'../../assets/images/members-en.jpeg'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Desktop images -->
      <div class="flex-iphone-container">
        <div class="flex-iphone-wrapper">
          <img class="flex-iphone" src="../../assets/svgs/phone.svg" alt="iphone">
          <div class="flex-iphone-content">
            <swiper-container appSwiper #swiperIphone [config]="swiperConfig" class="swiper-flex-iphone">
              <swiper-slide *ngFor="let step of ['conversation', 'reminders', 'members']">
                <img *ngIf="lang == 'fr'" [src]="'../../assets/images/' + step + '-fr.jpeg'">
                <img *ngIf="lang != 'fr'" [src]="'../../assets/images/' + step + '-en.jpeg'">
              </swiper-slide>
            </swiper-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->

<div class="content-container content-container-footer">
  <div class="hero-container">
    <h1>{{ 'HOME.box_footer_title' | translate }}</h1>
    <p class="paragraph">{{ 'HOME.box_footer_paragraph' | translate }}</p>
    <img (click)="redirectToStore('apple')" [src]="'../../assets/svgs/download-apple-' + theme + '.svg'" alt="download-apple" class="img-download" draggable="false">
  </div>
</div>
