import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { bigSlideLeftAnimation, fadeAnimation } from '../common/others/animations';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ThemeObservable } from '../common/observables/theme.observable';
import { environment } from 'src/environments/environment';
import { register, SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
register();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../app.component.scss'],
  animations: [fadeAnimation, bigSlideLeftAnimation]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  public hasSmallDevice: boolean = false;
  public lang: string | any = localStorage.getItem('lang') || 'en';
  public theme: string = localStorage.getItem('theme') || 'light';
  public langChangeEvent$: Subscription;
  public themeChangeEvent$: Subscription;
  public currentStep: number = 1;
  public displayOverlayIpad: boolean;
  public phoneCurrentStep: number = 1;
  public isIpadPreviewContainerInViewport: boolean = false;
  public hasStartedPlayingVideo: boolean = false;

  @ViewChild('videoIpad') videoIpad: ElementRef<HTMLVideoElement>;
  @ViewChild('ipadPreviewContainer') ipadPreviewContainer: ElementRef;
  @ViewChild('swiper') swiper: ElementRef<SwiperContainer>;
  @ViewChild('swiperIphone') swiperIphone: ElementRef<SwiperContainer>;
  @ViewChild('flexContentConversation') flexContentConversation: ElementRef;
  @ViewChild('flexContentReminders') flexContentReminders: ElementRef;
  @ViewChild('flexContentMembers') flexContentMembers: ElementRef;

  swiperConfig: SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 1,
    rewind: false,
    allowTouchMove: false,
    allowSlideNext: false,
    allowSlidePrev: false,
  }

  constructor(
    private translateService: TranslateService,
    private themeObservable: ThemeObservable)
  {
    this.themeChangeEvent$ = this.themeObservable.subjectUpdateThemeValue.subscribe(() => {
      this.theme = localStorage.getItem('theme') || 'light';
    });

    this.langChangeEvent$ = this.translateService.onLangChange.subscribe((event) => {
      this.lang = event.lang;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if(!this.flexContentConversation || !this.flexContentReminders || !this.flexContentMembers) return;

    const windowHeight = window.innerHeight;
    const conversationRect = this.flexContentConversation.nativeElement.getBoundingClientRect();
    const remindersRect = this.flexContentReminders.nativeElement.getBoundingClientRect();
    const membersRect = this.flexContentMembers.nativeElement.getBoundingClientRect();

    const isFlexContentConversationInTopHalf = conversationRect.top < windowHeight / 2 && conversationRect.bottom > 0;
    const isFlexContentRemindersInTopHalf = remindersRect.top < windowHeight / 2 && remindersRect.bottom > 0;
    const isFlexContentMembersInTopHalf = membersRect.top < windowHeight / 2 && membersRect.bottom > 0;

    let changePhoneSwiper = false;
    if(isFlexContentConversationInTopHalf && !isFlexContentRemindersInTopHalf && !isFlexContentMembersInTopHalf) {
      this.phoneCurrentStep = 1;
      changePhoneSwiper = true;
    } else if(isFlexContentRemindersInTopHalf && !isFlexContentMembersInTopHalf && !isFlexContentConversationInTopHalf) {
      this.phoneCurrentStep = 2;
      changePhoneSwiper = true;
    } else if(isFlexContentMembersInTopHalf && !isFlexContentConversationInTopHalf && !isFlexContentRemindersInTopHalf) {
      this.phoneCurrentStep = 3;
      changePhoneSwiper = true;
    }

    if(changePhoneSwiper) {
      this.swiperIphone.nativeElement.swiper.allowSlideNext = true;
      this.swiperIphone.nativeElement.swiper.allowSlidePrev = true;
      this.swiperIphone.nativeElement.swiper.slideTo(this.phoneCurrentStep - 1, 500);
      this.swiperIphone.nativeElement.swiper.allowSlideNext = false;
      this.swiperIphone.nativeElement.swiper.allowSlidePrev = false;
    }
  }

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;
  }

  ngAfterViewInit(): void {
    this.videoIpad.nativeElement.muted = true;

    this.videoIpad.nativeElement.addEventListener('canplaythrough', () => {
      this.displayOverlayIpad = false;
      this.videoIpad.nativeElement.pause();
      this.videoIpad.nativeElement.loop = false;

      let timeout = 1400;
      if(this.currentStep == 2 || this.currentStep == 4) timeout = 2500;

      setTimeout(() => {
        this.videoIpad.nativeElement.play();
        this.hasStartedPlayingVideo = true;
        setTimeout(() => {
          this.hasStartedPlayingVideo = false;
        }, 4000)
      }, timeout)
    }, false);

    this.videoIpad.nativeElement.addEventListener('ended', (event: any) => {
      if(this.hasStartedPlayingVideo) return;
      setTimeout(() => {
        if(this.currentStep != 2) {
          this.displayOverlayIpad = true;

          setTimeout(() => {
            this.videoIpad.nativeElement.currentTime = 0;
            this.videoIpad.nativeElement.pause();
          }, 500)

          setTimeout(() => {
            this.displayOverlayIpad = false;
            setTimeout(() => {
              this.videoIpad.nativeElement.play();
              setTimeout(() => {
                this.videoIpad.nativeElement.loop = false;
              }, 500)
            }, 1000)
          }, 1400)
        }
      }, 4000)
    });
  }

  ngOnDestroy(): void {
    this.themeChangeEvent$.unsubscribe();
    this.langChangeEvent$.unsubscribe();
  }

  // Redirect to the store
  public redirectToStore(store: string) {
    if(store == 'apple') {
      window.open(environment.appStoreUrl, '_blank');
    }
  }

  // Go to the next step
  public goNextStep() {
    if(this.displayOverlayIpad) return;
    this.displayOverlayIpad = true;
    setTimeout(() => {
      this.currentStep = this.currentStep + 1;
      this.swiper.nativeElement.swiper.allowSlideNext = true;
      this.swiper.nativeElement.swiper.slideNext(300);
      this.swiper.nativeElement.swiper.allowSlideNext = false;
    }, 800)
  }

  // Go to the previous step
  public goPreviousStep() {
    if(this.displayOverlayIpad) return;
    this.displayOverlayIpad = true;
    setTimeout(() => {
      this.currentStep = this.currentStep - 1;
      this.swiper.nativeElement.swiper.allowSlidePrev = true;
      this.swiper.nativeElement.swiper.slidePrev(300);
      this.swiper.nativeElement.swiper.allowSlidePrev = false;
    }, 800)
  }
}
